cs.ns('app.ui.composite.content')
app.ui.composite.content.model = cs.clazz({
    extend:   app.fw.abstract.model,
    mixin:    [],
    dynamics: {},
    protos:   {

        create () {
            this.base()
            cs(this).model({
                'data:contentURL':          { value: '', valid: 'string' },
                'event:contentChanged':     { value: false, valid: 'boolean', autoreset: true },
                'event:videoEnded':         { value: false, valid: 'boolean', autoreset: true },
                'event:error':              { value: false, valid: 'boolean', autoreset: true },
                'state:infoVisible':        { value: true, valid: 'boolean' },
                'state:ribbonVisible':      { value: true, valid: 'boolean' },
                'state:qrcodeVisible':      { value: true, valid: 'boolean' },
                'command:pauseVideo':       { value: false, valid: 'boolean', autoreset: true },
                'command:resumeVideo':      { value: false, valid: 'boolean', autoreset: true }
            })
        }
    }
})